<template>
  <div class="comprehensive-table-container">
    <vab-query-form>
      <vab-query-form-top-panel>
        <el-form
          ref="queryForm"
          :inline="true"
          :model="queryForm"
          @submit.native.prevent
        >
          <el-form-item label-width="80px" label="操作用户">
            <el-input
              clearable
              v-model="queryForm.userName"
              placeholder="请输入登录账户"
            />
          </el-form-item>
          <el-form-item label-width="50px" label="时间">
            <el-date-picker
              v-model="beginTime"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              value-format="yyyy-MM-dd"
              :picker-options="pickerOptions"
              unlink-panels
              @change="pickerClick"
              style="width: 350px"
            ></el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-button
              icon="el-icon-search"
              native-type="submit"
              type="primary"
              @click="handleQuery"
            >
              查询
            </el-button>
          </el-form-item>
          <el-form-item>
            <el-button native-type="submit" @click="reset('queryForm')">
              重置
            </el-button>
          </el-form-item>
        </el-form>
      </vab-query-form-top-panel>
    </vab-query-form>

    <el-table
      ref="tableSort"
      v-loading="listLoading"
      border
      :data="list"
      element-loading-text="拼命加载中"
    >
      <el-table-column
        align="center"
        prop="userName"
        label="操作用户"
        show-overflow-tooltip
        width="120"
      />
      <el-table-column
        align="center"
        prop="ip"
        label="操作IP"
        show-overflow-tooltip
        width="150"
      />
      <el-table-column
        align="center"
        prop="createTime"
        label="操作时间"
        show-overflow-tooltip
        width="160"
      />
      <el-table-column
        align="center"
        prop="operation"
        label="操作描述"
        show-overflow-tooltip
        width="180"
      />
      <el-table-column
        align="center"
        prop="inputPrarms"
        label="入参"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        prop="outputPrarms"
        label="出参"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        prop="useTime"
        label="耗时(ms)"
        show-overflow-tooltip
        width="100"
      />
      <template #empty>
        <el-image
          class="vab-data-empty"
          :src="require('@/assets/empty_images/data_empty.png')"
        />
      </template>
    </el-table>
    <el-pagination
      background
      :current-page="queryForm.pageNum"
      :layout="layout"
      :page-size="queryForm.pageSize"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
  </div>
</template>

<script>
import { pageBaseSystemLog } from '@/api/cmsApi'
export default {
  name: 'baseSystemLog',
  components: {
  },
  data() {
    return {
      list: [],
      listLoading: false,
      beginTime:[],
      layout: 'total, sizes, prev, pager, next, jumper',
      total: 0,
      height: this.$baseTableHeight(3) + 50,
      queryForm: {
        userName: '',
        startTime: '',
        endTime: '',
        pageNum: 1,
        pageSize: 30,
      },
      pickerOptions: {},
    }
  },
 
  created() {
    this.queryList()
  },
  methods: {
    //重置
    reset(queryForm) {
      this.beginTime = []
      this.queryForm.startTime = ''
      this.queryForm.endTime = ''
      this.$refs[queryForm].resetFields()
      this.queryForm.pageNum = 1
      this.queryList()
    },
    handleSizeChange(val) {
      this.queryForm.pageNum = 1
      this.queryForm.pageSize = val
      this.queryList()
    },
    handleCurrentChange(val) {
      this.queryForm.pageNum = val
      this.queryList()
    },
    pickerClick() {
      this.queryForm.startTime = this.beginTime ? this.beginTime[0] + ' 00:00:00': ''
      this.queryForm.endTime = this.beginTime ? this.beginTime[1] + ' 00:00:00': ''
      this.queryList()
    },
    handleQuery() {
      this.queryForm.pageNum = 1
      this.queryList()
    },
    //查询
    async queryList() {
      this.listLoading = true
      const res = await pageBaseSystemLog(this.queryForm)
      this.total = res.data.totalSize
      this.list = res.data.pageList
      this.listLoading = false
    },
  },
}
</script>
<style lang="scss" scoped>
.comprehensive-table-container {
  position: relative;
}
::v-deep .left-panel {
  margin: 0px !important;
}
.posit {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
}
</style>